// const getSign = require('./getSign')
import getSign from "./getSign";
import { showMessage } from "@/components/dialog/message";
import store from "../../store/index";
import { VUE_APP_ID, device_id } from "@/assets/js/stationSetting";

let app_id = VUE_APP_ID;

if (!app_id) {
  app_id = localStorage.getItem("web-id");
}
// module.exports = async option => {
export default async option => {
  let tempLang = localStorage.getItem("language");
  option.data.lang = tempLang || "zh_CN";
  option.data.app_id = app_id;
  option.data.__platform = 3;

  const token =
    window.$.cookie("topcredit_exchange_t") ||
    sessionStorage.getItem("topcredit_exchange_t");
  let req = {
    device_id,
    token,
    ...option.data,
  };
  req._CDCODE = getSign(req, app_id);
  let result = "";
  const headers = {};
  // 上传图片，添加upload属性
  if (option.upload) {
    const formData = new FormData();
    for (const field in req) {
      formData.append(field, req[field]);
    }
    result = formData;
  } else {
    headers["content-type"] = "application/x-www-form-urlencoded";
    // headers["accept-encoding"] = "gzip,deflate";
    const arr = [];
    for (const field in req) {
      arr.push(field + "=" + req[field]);
    }
    result = arr.join("&");
  }
  return (function p() {
    return new Promise(function(resolve, reject) {
      window.$.ajax({
        dataType: option.dataType || "text",
        type: option.type || "POST",
        url: option.url,
        data: result || "",
        headers,
        processData: false,
        contentType: false,
        success: function(res) {
          if (typeof res == "string") {
            res = JSON.parse(res);

            if (res.status == -103) {
              showMessage({
                message: "系统当前升级维护中",
                type: "error",
                duration: 3000,
              });
              return;
            }
            if (res.status == -105 || res.status == -108) {
              store.dispatch("user/logout");
              // 关闭usdt长连接
              // window.location.reload();
              // location.href = "/login";
            }
            if (res.status != 200 && res.status != -105) {
              if (res.msg != "") {
                showMessage({
                  message: res.msg,
                });
              } else {
                showMessage({
                  message: "Network error, please try again later",
                  type: "error",
                });
              }
            }
          }
          resolve(res);
        },
        error: function(res) {
          if (typeof res == "string") {
            res = JSON.parse(res);
          }
          showMessage({
            message: "Network error, please try again later",
            type: "error",
          });
          reject(res);
          if (option.retry) {
            setTimeout(() => {
              p();
            }, 5000);
          }
        },
      });
    });
  })();
};

//图片上传
export function fileUpload(url, params, error) {
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  }; //添加请求头

  return new Promise(resolve => {
    axios.post(url, params, config).then(
      res => {
        resolve(res.data);
      },
      err => {
        err = error ? error : err;
        // Message.error(err)
        showMessage({
          message: err,
          type: "error",
        });
      }
    );
  });
}
