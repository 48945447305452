<template>
  <div>
    <MaskHomeContent></MaskHomeContent>
  </div>
</template>

<script>
import MaskHomeContent from "./mask-home-content.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    MaskHomeContent,
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      darkMode: "user/darkMode",
    }),
  },
  created() {},
};
</script>

<style></style>
