<template>
  <div class="contract-page-exchange">
    <div v-show="!isFullScreen" class="layout-block-first">
      <market
        ref="marketComponent"
        :marketList="marketList"
        @onAddOption="onMarketAddOption"
        @on-symbol-change="onSymbolChange"
      ></market>
    </div>
    <div
      class="layout-block-second"
      :style="{ height: isFullScreen ? '100%' : '' }"
    >
      <panel
        ref="panelComponent"
        :swap_symbol_id="swap_symbol_id"
        :tradePairArray="tradePairArray"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        :frObj="frObj"
        @onAddOption="onAddOption"
        @onMultipleNumClick="onMultipleNumClick"
      ></panel>
      <kline
        ref="klineComponent"
        :symbol="symbol"
        :coin-from="coinFrom"
        :coin-to="coinTo"
        :depthList="depthList"
        @full-screen="zoomKline"
      ></kline>
      <trade
        v-show="!isFullScreen"
        ref="tradeComponent"
        @onMultipleNumClick="onMultipleNumClick"
        :swap_symbol_id="swap_symbol_id"
        :assetsInfo="assetsInfo"
        :marketList="marketList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        @on-trade-success="onTradeSuccess"
        :from_item_id="from_item_id"
        :to_item_id="to_item_id"
      ></trade>
    </div>
    <div v-show="!isFullScreen" class="layout-block-six">
      <div class="block-six-header">
        <ul>
          <li
            class="classify-area"
            :class="{ active: currIndex == item.value }"
            v-for="item of tabList"
            :key="item.value"
            @click="onAreaChange(item)"
          >
            {{ translateTitle(item.label) }}
            <i v-if="currIndex == item.value" class="item-bar"></i>
          </li>
        </ul>
      </div>
      <depth
        v-show="currIndex == 1"
        :showTitle="false"
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :tradePair="tradePair"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
      <deal
        :showTitle="false"
        v-if="currIndex == 2"
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div>
    <div v-show="!isFullScreen" class="layout-block-third">
      <depth
        :depthList="depthList"
        :swap_symbol_id="swap_symbol_id"
        :marketList="marketList"
        :tradePair="tradePair"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></depth>
    </div>
    <div v-show="!isFullScreen" class="layout-block-fourth">
      <deal
        :orderbookList="orderbookList"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
      ></deal>
    </div>
    <div v-show="!isFullScreen" class="layout-block-five">
      <history-order
        :marketList="marketList"
        :assetsInfo="assetsInfo"
        :swap_symbol_id="swap_symbol_id"
        :from_symbol="coinFrom"
        :to_symbol="coinTo"
        :from_item_id="from_item_id"
        :to_item_id="to_item_id"
        @changeFundingRate="fundingRateObj"
        @on-symbol-change="onSymbolChangeV2"
        ref="historyOrder"
      ></history-order>
    </div>

    <el-dialog
      :title="translateTitle('调整杠杆')"
      custom-class="multiple-dialog"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div>
        <span
          class="symbol-item"
          @click="onMinClick"
          :class="multipleNum == 1 ? 'disabled' : ''"
          >-</span
        >
        <InputNumber
          :disabled="!userInfo"
          v-model="multipleNum"
          @input="onInputChange"
          class="buy-price"
          autocomplete="new-password"
          maxlength="3"
          decimals="0"
          :placeholder="translateTitle('请输入倍数')"
          ref="buyPrice"
        ></InputNumber>
        <span
          class="symbol-item"
          @click="onPlusClick"
          :class="
            multipleNum >= maxMultipleNum[swap_symbol_id] ? 'disabled' : ''
          "
          >+</span
        >
      </div>
      <div class="block">
        <!-- :disabled="!userInfo" -->
        <el-slider
          :disabled="!userInfo"
          @input="onSliderChange"
          :show-tooltip="false"
          v-model="sliderValue"
          :min="1"
          :max="maxMultipleNum[swap_symbol_id]"
          :format-tooltip="formatTooltip"
          :marks="marks[swap_symbol_id]"
        >
          <!-- :step="sliderStep[swap_symbol_id]" -->
        </el-slider>
      </div>
      <span slot="footer" class="dialog-footer" v-if="userInfo">
        <span class="cancel-btn" @click="dialogVisible = false">{{
          translateTitle("取消")
        }}</span>
        <span class="confirm-btn" @click="onMultipleNumConfirm">{{
          translateTitle("确定")
        }}</span>
      </span>
      <span slot="footer" class="dialog-footer-v2" v-else>
        {{ translateTitle("请") }}
        <span class="common-operate" @click="goToLogin">{{
          translateTitle("登录")
        }}</span>
        {{ translateTitle("或") }}
        <span class="common-operate" @click="goToRegister">{{
          translateTitle("注册")
        }}</span>
        {{ translateTitle("调整杠杆") }}
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Socket from "@/assets/js/socket.js";
import Kline from "./components/contract-kline.vue";
import Depth from "./components/contract-depth.vue";
import Deal from "./components/contract-deal.vue";
import Market from "./components/contract-market.vue";
import Trade from "./components/contract-trade.vue";
import Panel from "./components/contract-panel.vue";
import InputNumber from "@/components/common/InputNumber";
import { mapGetters } from "vuex";
import store from "@/store/index";
import Cookies from "js-cookie";
import HistoryOrder from "./components/contract-history-order.vue";
import { apiGetOrderStatistic } from "@/model/contractExchange/order.js";
import { apiWsUrl, apiGetDefaultToken } from "@/model/contractExchange/index";
import { apiGetStaticTableInfo } from "@/model/settings";
import { showMessage } from "@/components/dialog/message";
import commonMixin from "@/components/common/commonMixin.vue";
import { VUE_APP_ID } from "@/assets/js/stationSetting";
import routeSplicing from "@/assets/js/routeSplicing";
export default {
  mixins: [commonMixin],

  name: "ContractExchange",
  components: {
    Deal,
    Kline,
    Depth,
    Market,
    Panel,
    Trade,
    InputNumber,
    HistoryOrder,
  },
  data() {
    return {
      tt: {}, //WS延时触发器
      lockReconnect: false, //避免重复连接
      tabList: [
        {
          value: "1",
          label: "盘口",
        },
        {
          value: "2",
          label: "实时成交",
        },
      ],
      currIndex: "1",
      multipleNum: 0,
      mixMultipleNum: 1,
      maxMultipleNum: {},
      dialogVisible: false,
      sliderStep: {},
      sliderValue: 0,
      symbol: this.$route.params.symbol || "btcusdt",
      coinFrom: "--",
      coinTo: "--",
      coinInfoMap: {},
      coinItemIdMap: {},
      tradePair: [],
      tradePairArray: [],
      marketList: [],
      assetsInfo: {
        remain: {
          all_u_valuation: {
            usdt: "--",
          },
          list: [],
        },
      },
      assetsInfoList: [],
      orderbookList: [
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
      ],
      klineList: [],
      depthList: {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      },
      swap_symbol_market: {},
      swap_symbol_id: "",
      old_swap_symbol_id: "",
      from_item_id: "",
      to_item_id: "",
      wsUrl: "",
      token: "",
      pageLoading: false,
      onload: false,
      voucherInterval: null,
      historyDataLength: 0,
      wsContentTime: 0,
      marks: {},
      maxNumber: {},
      marketInfo: {
        last: "",
        currency: { cny: "" },
      },
      frObj: {}, //资金费率中转
      isFullScreen: false,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "user/userInfo",
      language: "user/language",
    }),

    title() {
      let title = "数字货币银行";
      title = "Cryptocurrency Exchange";

      let val =
        this.marketInfoShow.last +
        "  " +
        this.coinFrom.toUpperCase() +
        "  " +
        this.translateTitle("永续") +
        "/" +
        this.coinTo.toUpperCase() +
        " " +
        this.translateTitle(title);
      return val;
    },
    marketInfoShow() {
      let tempMarketInfo = {
        last: "",
        currency: { cny: "" },
      };
      this.marketList.forEach(ele => {
        if (ele.symbol_id == this.swap_symbol_id) {
          tempMarketInfo = ele;
        }
      });
      return tempMarketInfo;
    },
  },
  async created() {
    // await this.getTradePairList();
    await this.getExchangeItemId();
    await this.getUrlSymbol();
    let tempCookies = Cookies.get("topcredit_exchange_t");
    if (tempCookies == undefined) {
      // await this.getToken();
      await this.initURL();
      this.initWs();
    } else {
      this.token = Cookies.get("topcredit_exchange_t");
      await this.initURL();
      this.initWs();
    }
    this.resolveCoin();
  },
  mounted() {
    this.voucherInterval = setInterval(() => {
      document.title = this.title;
      // document.getElementById("chat-widget-container").style.display = "none"; //隐藏
    }, 500);
  },
  destroyed() {
    clearInterval(this.voucherInterval);
  },
  beforeDestroy() {
    clearInterval(this.voucherInterval);
  },
  watch: {
    async userInfo(newValue, oldValue) {
      if (this.userInfo == null) {
        // await this.getToken();
        await this.initURL();
        this.initWs();
      } else {
        let tempUserInfo = sessionStorage.getItem("userInfo");
        if (tempUserInfo) {
          return;
        }
        this.token = Cookies.get("topcredit_exchange_t");
        await this.initURL();
        this.initWs();
      }
    },
  },
  methods: {
    routeSplicing,
    zoomKline(isFull) {
      this.isFullScreen = isFull;
    },
    //传递资金费率
    fundingRateObj(frObj) {
      this.frObj = frObj;
    },
    onClosePosition() {
      this.$confirm(
        this.translateTitle("确认平仓该订单?"),
        this.translateTitle("平仓"),
        {
          confirmButtonText: this.translateTitle("确定"),
          cancelButtonText: this.translateTitle("取消"),
        }
      ).then(() => {});
    },
    onMinClick() {
      this.multipleNum -= 1;
      if (this.multipleNum <= 1) {
        this.multipleNum = 1;
      }
    },
    onPlusClick() {
      this.multipleNum += 1;
      if (this.multipleNum >= this.maxNumber[this.swap_symbol_id]) {
        this.multipleNum = this.maxNumber[this.swap_symbol_id];
      }
    },
    goToLogin() {
      this.$router.push({
        path: this.routeSplicing(this.language, "login"),
        query: { redirect: this.$route.fullPath },
      });
    },
    goToRegister() {
      this.$router.push(this.routeSplicing(this.language, "register"));
    },
    formatTooltip(val) {
      return val + "X";
    },
    onInputChange(value) {
      if (Number(value) > Number(this.maxNumber[this.swap_symbol_id])) {
        value = this.maxNumber[this.swap_symbol_id];
      }
      this.sliderValue = Number(value);
      this.multipleNum = value;
    },
    onSliderChange(value) {
      this.multipleNum = value;
      if (this.multipleNum >= this.maxNumber[this.swap_symbol_id]) {
        this.multipleNum = this.maxNumber[this.swap_symbol_id];
      }
    },
    handleClose() {
      this.multipleNum = 20;
      this.dialogVisible = false;
    },
    async onMultipleNumClick(value) {
      if (this.userInfo) {
        this.sliderValue = Number(value);
        this.multipleNum = value;
        let params = {
          symbol_id: this.swap_symbol_id,
        };
        const { status, data, msg } = await apiGetOrderStatistic(params);
        if (status == 200) {
          if (data.contract_symbol_limit.indexOf(this.swap_symbol_id) > -1) {
            showMessage({
              message: this.translateTitle(
                "当前有进行中的仓位或委托单，不能修改杠杆倍数"
              ),
              type: "error",
            });
          } else {
            this.dialogVisible = true;
          }
          // this.historyDataLength =
          //       data.contract_position_info.total_num +
          //       data.contract_plan_info.total_num;
          // if (this.historyDataLength == 0) {
          //   this.dialogVisible = true;
          // } else {
          //   showMessage({
          //     message: this.translateTitle("当前有进行中的仓位或委托单，不能修改杠倍数"),
          //     type: "error",
          //   });
          // }
        } else {
          showMessage({
            message: msg,
            type: "error",
          });
        }
      } else {
        this.$router.push({
          path: this.routeSplicing(this.language, "login"),
          query: { redirect: this.$route.fullPath },
        });
      }
    },
    onMultipleNumConfirm() {
      // localStorage.setItem("contract-multiple-num", Number(this.multipleNum));
      // this.resetSetItem("contract-multiple-num", Number(this.multipleNum));
      this.$refs.panelComponent.setMultipleNum(Number(this.multipleNum));
      this.$refs.tradeComponent.setMultipleNum(Number(this.multipleNum));
      this.dialogVisible = false;
    },
    onAddOption() {
      this.$refs.marketComponent.getOptionList();
    },
    onMarketAddOption() {
      this.$refs.panelComponent.getOptionList();
    },
    onAreaChange(item) {
      this.currIndex = item.value;
    },

    onTradeSuccess(type) {
      this.$refs.historyOrder.reset(type);
    },
    async getExchangeItemId() {
      const { status, data } = await apiGetStaticTableInfo(13);
      if (status == 200) {
        this.coinItemIdMap = JSON.parse(JSON.stringify(data));
      }
    },
    async getUrlSymbol() {
      const { status, data } = await apiGetStaticTableInfo(49);
      if (status == 200) {
        this.coinInfoMap = JSON.parse(JSON.stringify(data));

        let symbolArr = [];
        for (const key in this.coinInfoMap) {
          symbolArr.push(this.coinInfoMap[key].pair.toLowerCase());
        }
        let tempSymbol = this.$route.params.symbol;

        if (symbolArr.indexOf(tempSymbol) > -1) {
          this.symbol = tempSymbol;
        } else {
          tempSymbol = "btcusdt";
          this.symbol = "btcusdt";
        }
        for (const key in this.coinInfoMap) {
          let tempArr = [];
          tempArr = this.coinInfoMap[key].leverage_list.split(",");
          this.maxMultipleNum[key] = Number(tempArr[tempArr.length - 1]);
          let tempBase = tempArr[tempArr.length - 1] / 5;
          this.marks[key] = {};
          // this.maxNumber[key] = {};
          this.maxNumber[key] = tempArr[tempArr.length - 1];
          tempArr.forEach((ele, index) => {
            this.marks[key][1] = "1X";
            this.marks[key][tempBase * (index + 1)] =
              tempBase * (index + 1) + "X";
          });
          this.tradePairArray.push(this.coinInfoMap[key].pair.toLowerCase());

          if (this.coinInfoMap[key].pair.toLowerCase() == tempSymbol) {
            this.swap_symbol_id = this.coinInfoMap[key].symbol_id;
            this.from_item_id = this.coinInfoMap[key].change_item_id;
            this.to_item_id = this.coinInfoMap[key].base_item_id;
          }
        }
      }
    },
    onSymbolChangeV2(params) {
      this.$refs.marketComponent.onSymbolChange(params, "1");
    },

    onSymbolChange(item) {
      if (this.swap_symbol_id == item.symbol_id) {
        return;
      }
      this.old_swap_symbol_id = this.swap_symbol_id;
      // window.ws.sub({
      //   this.global.ws.sub({
      //     action: "Topic.unsub",
      //     data: {
      //       type: "market",
      //       symbol: this.swap_symbol_id,
      //     },
      //   });
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "order",
          symbol: this.swap_symbol_id,
        },
      });
      this.global.ws.sub({
        action: "Topic.unsub",
        data: {
          type: "depth",
          symbol: this.swap_symbol_id,
        },
      });
      this.orderbookList = [
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
        {
          E: "--",
          T: "--",
          m: "--",
          p: "--",
          q: "--",
        },
      ];
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "market",
          symbol: item.symbol_id,
        },
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "order",
          symbol: item.symbol_id,
        },
      });
      this.global.ws.sub({
        action: "Topic.sub",
        data: {
          type: "depth",
          symbol: item.symbol_id,
        },
      });
      this.depthList = {
        a: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
        b: [
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
          ["--", "--"],
        ],
      };

      this.$refs.klineComponent.onChangeSymbol(item);
      this.swap_symbol_id = item.symbol_id;
      this.from_item_id = item.change_item_id;
      this.to_item_id = item.base_item_id;
      this.resolveCoin();
      // this.getUrlSymbol();
      // this.$refs.historyOrder.resetV2();
    },

    resolveCoin() {
      this.pageLoading = true;
      let tempSymbol = this.$route.params.symbol;
      if (!tempSymbol) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 1010;
      }

      let trade_pair = tempSymbol;
      if (this.tradePairArray.indexOf(trade_pair.toLowerCase()) == -1) {
        tempSymbol = "btcusdt";
        this.swap_symbol_id = 1010;
      }

      this.coinFrom = this.coinItemIdMap[
        this.from_item_id
      ].item_name.toUpperCase();
      this.coinTo = this.coinItemIdMap[this.to_item_id].item_name.toUpperCase();
      // const [coinFrom, coinTo] = tempSymbol.split("_");
      // this.coinFrom = coinFrom;
      // this.coinTo = coinTo;
      setTimeout(() => {
        this.pageLoading = false;
      }, 300);
    },
    async getToken() {
      const { status, data } = await apiGetDefaultToken(); //接口废弃不用了
      if (status == 200) {
        this.token = data.token;
      }
    },
    async initURL() {
      let params = {
        __version: "3.4.3",
        // token: this.token,
      };
      const { status, data } = await apiWsUrl(params);
      if (status == 200) {
        this.wsUrl = data.ws3_url;
      }
    },
    initWs() {
      const me = this;

      const option = {
        url: this.wsUrl,
        subData: {
          action: "Topic.sub",
          data: {
            type: "kline",
            resolution: "15",
            symbol: me.swap_symbol_id,
          },
        },
        callback,
        onConnect,
        mounted,
      };

      let wsNew = new Socket(option).getInstance();
      me.global.setWs(wsNew);
      // 重新连接
      function onConnect() {
        if (me.lockReconnect) return;
        if (me.wsContentTime > 3) {
          window.location.reload();
        }
        me.lockReconnect = true;
        me.tt && clearTimeout(me.tt);
        me.tt = setTimeout(() => {
          me.wsContentTime++;
          console.log("ws重連");
          me.initWs();
          me.lockReconnect = false;
        }, 3000);
      }
      function mounted() {
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "market",
            symbol: me.swap_symbol_id,
            // resolution: "D",
          },
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "depth",
            symbol: me.swap_symbol_id,
            app_id: VUE_APP_ID,
            // resolution: "D",
          },
        });
        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "order",
            symbol: me.swap_symbol_id,
            app_id: VUE_APP_ID,
          },
        });

        me.global.ws.sub({
          action: "Topic.sub",
          data: {
            type: "contractM",
            app_id: VUE_APP_ID,
          },
        });
        if (Cookies.get("topcredit_exchange_t")) {
          me.global.ws.sub({
            action: "Topic.sub",
            data: {
              type: "assets",
            },
          });
        }
      }
      function callback(data) {
        const { action } = data;
        if (action == "Pushdata.contractM") {
          if (data.data) {
            let tempArr = data.data;
            tempArr.forEach(ele => {
              ele.lastV2 = Number(ele.last);
              ele.currency.usdV2 = Number(ele.usd);
              ele.price_change_percentV2 = Number(ele.price_change_percent);
            });
            me.marketList = tempArr;
          }
        }
        if (action == "Pushdata.kline") {
          window.resolveKlineDataV2(data);
        }
        // 资产
        if (action == "Pushdata.assets") {
          if (data.data == null || data.data instanceof Array) {
            return;
          }
          me.assetsInfo = data.data;
        }
        if (action == "Pushdata.orderbook") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            me.orderbookList.unshift(...data.data);
            if (me.orderbookList.length > 28) {
              me.orderbookList = me.orderbookList.slice(0, 28);
            }
          }
        }
        if (action == "Pushdata.depth") {
          if (data.data) {
            if (!data.params.p || data.params.p == me.old_swap_symbol_id) {
              return;
            }
            let tempObj = data.data;
            if (tempObj.a.length > 0) {
              tempObj.c = 0;
              tempObj.d = 0;
              tempObj.a.forEach(ele => {
                tempObj.c += Number(ele[1]);
              });
              tempObj.b.forEach(ele => {
                tempObj.d += Number(ele[1]);
              });
            }
            me.depthList = tempObj;
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.contract-page-exchange {
  min-width: 1355px;
  @media screen and (min-width: 0px) and (max-width: 1650px) {
    .contract-component-history-order {
      overflow-x: auto;
    }
  }
  @media screen and (min-width: 0px) and (max-width: 1860px) {
    .layout-block-six {
      display: block;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: none;
    }
  }
  @media screen and (min-width: 1860px) {
    .layout-block-six {
      display: none;
    }
    .layout-block-third,
    .layout-block-fourth {
      display: block;
    }
  }
  margin: 0 auto;
  padding-top: 10px;
  display: flex;
  flex-wrap: wrap;
  background: #ecedf1;
  .layout-block-first {
    width: 310px;
    height: 804px;
    margin-right: 8px;
  }
  .layout-block-second {
    flex: 1;
    height: 804px;
    min-width: 700px;
  }
  .layout-block-third {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
    overflow: hidden;
  }

  .layout-block-fourth {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
  }
  .layout-block-five {
    width: 100%;
    overflow-x: auto;
    // height: 280px;
    flex-basis: 100%;
    background: #ecedf1;
  }
  .layout-block-six {
    max-width: 328px;
    background: #fff;
    height: 804px;
    margin-left: 8px;
    overflow: hidden;
    .block-six-header {
      background-color: #f5f5f7;
      ul {
        display: flex;
      }
      .classify-area {
        padding: 0 20px;
        cursor: pointer;
        height: 32px;
        font-size: 14px;
        font-weight: 600;
        color: #1f2533;
        line-height: 32px;
        position: relative;
        &.active {
          background-color: #ffffff;
          color: #000;
        }
        .item-bar {
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #1f2533;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
        }
      }
    }
  }
  .multiple-dialog {
    .el-dialog__header {
      .el-dialog__title {
        font-size: 20px;
        font-weight: 500;
        color: #354052;
      }
    }
    .el-dialog__footer {
      padding: 10px 20px 30px;
    }
    .el-dialog__body {
      padding: 10px 30px 35px;
      .symbol-item {
        text-align: center;
        color: #c2c7d0;
        line-height: 13px;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }
      span {
        width: 50px;
        height: 40px;
        display: inline-block;
        border-radius: 2px;
        background-color: #fff;
        border: solid 1px #e2e2e4;
        padding: 12px 17px;
        vertical-align: middle;
      }

      .buy-price {
        width: 330px;
        display: inline-block;
        border-radius: 2px;
        background-color: #fff;
        vertical-align: middle;
        margin: 0 5px;
        .el-input__inner {
          background-color: #fff;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #354052;
        }
      }
      .el-slider__marks {
        .el-slider__marks-text:last-child {
          left: 93% !important;
          transform: translateX(0%);
        }
      }
      .el-slider__bar {
        background-color: #c61b3d;
      }
      .el-slider__button {
        border: 2px solid #c61b3d;
      }
      .el-slider {
        .disabled {
          .el-slider__button {
            border-color: #c0c4cc;
          }
        }
      }
    }
    .dialog-footer {
      display: flex;
      justify-content: center;
      span {
        display: inline-block;
        width: 160px;
        height: 40px;
        border-radius: 2px;
        padding: 12px 15px;
        font-size: 14px;
        text-align: center;
        line-height: 15px;
        cursor: pointer;
      }
      .cancel-btn {
        color: #354052;
        border: solid 1px #bec1c8;
        margin-right: 5px;
      }
      .confirm-btn {
        color: white;
        margin-left: 5px;
        background-image: linear-gradient(267deg, #ff486d 10%, #c61b3d 41%);
      }
    }
    .dialog-footer-v2 {
      display: block;
      width: 280px;
      height: 40px;
      margin: 0 auto;
      padding: 7px 6px 9px 6px;
      border-radius: 2px;
      border: solid 1px #bec1c8;
      font-size: 14px;
      text-align: center;
      color: #354052;
    }
  }
}
</style>
