<template>
  <div style="min-height:100vh;">
    <SituationInner></SituationInner>
  </div>
</template>
<script>
import { translateTitle } from "@/assets/js/i18n";
import SituationInner from "@/pages/ucenter/situation-inner.vue";
export default {
  mixins: [],

  data() {
    return {};
  },
  components: { SituationInner },
  computed: {},
  created() {},
  beforeDestroy() {},
  destroyed() {},
  mounted() {},
  methods: {
    translateTitle,
  },
};
</script>
<style lang="scss"></style>
